
import * as React from "react";
import { Redirect } from "react-router-dom";
import Router from "../defaults/Router.tsx";
import requestPilets from "../defaults/requestPilets.ts";
import plugins from "../defaults/plugins.ts";
import setup from "../defaults/setup.ts";

function replaceAllParams(path, params) {
  Object.keys(params).forEach(key => {
    path = path.split(':' + key).join(params[key]);
  });

  return path;
}

const NotFoundPage = React.lazy(() => import("../defaults/NotFoundPage.tsx"));
const version = "1.3.0";
const updated = "Mon Jan 31 2022";
const publicUrl = "/";
const pages = {
  
};
const layouts = {
  "default": React.lazy(() => import("../defaults/Layout.tsx"))
};

export { Router, NotFoundPage, requestPilets, pages, plugins, setup, version, updated, publicUrl, layouts };
