
import Footer from "../defaults/Footer.tsx";
import Header from "../defaults/Header.tsx";
import Logo from "../defaults/Logo.tsx";
import InfoBar from "../defaults/InfoBar.tsx";
import Breadcrumbs from "../defaults/Breadcrumbs.tsx";
import SectionNav from "../defaults/SectionNav.tsx";

const brandName = "Docs";

export { Footer, Header, Logo, InfoBar, Breadcrumbs, SectionNav, brandName };
