
import filterSitemap from "../defaults/filter.ts";


const { lazy } = require('react');

function populate(source) {
  const results = [];

  source.forEach(item => {
    const title = item.section;
    const [result] = results.filter(m => m.title === title);

    if (result) {
      result.links.push(item);
    } else {
      results.push({
        title,
        links: [item],
      });
    }
  });

  return results;
}


export default filterSitemap({  });
